<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-dialog
        v-model="dialogLoading"
        hide-overlay
        v-if="loading"
        persistent
        width="300"
      >
        <v-card color="primary" dark>
          <v-card-text>
            <h2 class="white--text">Loading</h2>
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-col cols="12" md="12">
        <!-- วิทยาลัยรายงานตัว รองผู้อำนวยการ  -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="period_college_redirector.period_college_enable === '1'"
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>รับรายงานตัว <u> รองผู้อำนวยการวิทยาลัย</u></h2>
                      <h3>
                        ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                        {{
                          period_college_redirector.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h4>
                        * เฉพาะวิทยาลัยที่ได้รับ รองผู้อำนวยการวิทยาลัย
                      </h4>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn color="primary" to="/college/sedirector_app_report"
                        >ดำเนินการ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ วันที่ :
                    {{
                      period_college_redirector.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_college_redirector.period_college_stop +
                        ' 16:30:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <v-alert
          prominent
          type="error"
          v-if="countmanage_assistant_teachers.nullsCounts >= 1"
        >
          <v-row align="center">
            <v-col class="grow">
              <h2>
                ยังไม่ได้ดำเนินการแต่งตั้งคณะกรรมการประเมินครูผู้ช่วย จำนวน
                {{ countmanage_assistant_teachers.nullsCounts }} ราย
              </h2>
            </v-col>
            <v-col class="shrink">
              <v-btn color="success" to="/college/assistantcommitteequalified"
                >ดำเนินการ</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>

        <!-- ปรับปรุงข้อมูลข้าราชการครู -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="periodassteachs.periodAssTeachEnable === '1'"
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>
                        ดำเนินการ
                        <u v-if="periodassteachs.periodAssTeachTimes >= 1"
                          >รับรายงานตัวครูผู้ช่วย รอบ
                          {{ periodassteachs.periodAssTeachTimes }} :
                          {{ periodassteachs.periodAssTeachYear }}</u
                        >
                        <u v-else>รับรายงานตัวครูผู้ช่วย รอบคัดเลือกภายใน</u>
                      </h2>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          periodassteachs.periodAssTeachStop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>

                    <v-col class="shrink">
                      <v-btn
                        color="primary"
                        to="/college/manage_assistant_teacher"
                        >ดำเนินการ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ :
                    {{
                      periodassteachs.periodAssTeachStop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="periodassteachs.periodAssTeachStop + ' 16:30:00'"
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- ปรับปรุงข้อมูลข้าราชการครู -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              periodTeachers.period_enable === '1' &&
                periodTeachers.period_type === 'teacher'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>
                        ดำเนินการ
                        <u>ปรับปรุงข้อมูลข้าราชการครู เพื่อใช้ในการเสนอย้าย </u>
                      </h2>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          periodTeachers.period_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>

                    <v-col class="shrink">
                      <v-btn color="primary" to="/college/personnel_tem"
                        >ดำเนินการ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                    {{
                      periodTeachers.period_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="periodTeachers.period_stop + ' 16:30:00'"
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- แจ้งเตือนเงือนไขการย้าย -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              period_college_moves.period_college_enable === '1' &&
                period_college_moves.period_college_type === 'movement_college'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>
                        ดำเนินการ
                        <u>แจ้งเงื่อนไขสาขาวิชาและจำนวนที่ต้องการรับย้าย </u>
                      </h2>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          period_college_moves.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                    <v-col
                      class="shrink"
                      v-if="conditions_transfers.documentsLinkfile"
                    >
                      <v-btn
                        color="info"
                        :href="
                          '/HRvecfiles/' +
                            conditions_transfers.documentsLinkfile
                        "
                        target="_blank"
                        >เสร็จสิ้น</v-btn
                      >
                    </v-col>
                    <v-col class="shrink" v-else>
                      <v-btn color="primary" to="/college/conditions_branch"
                        >ดำเนินการ</v-btn
                      >
                      <v-btn text color="red" @click="VideoDialog()">
                        <v-icon large color="red">mdi-video</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                    {{
                      period_college_moves.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_college_moves.period_college_stop + ' 16:30:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- แจ้งเตือนเงือนไขการย้ายแนบไฟล์ -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              period_collegeFiles.period_college_enable_file === '1' &&
                period_collegeFiles.period_college_type === 'movement_college'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>
                        ดำเนินการ
                        <u>แจ้งเงื่อนไขสาขาวิชาและจำนวนที่ต้องการรับย้าย </u>
                      </h2>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          period_collegeFiles.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                    <v-col
                      class="shrink"
                      v-if="conditions_transfers.documentsLinkfile"
                    >
                      <v-btn
                        color="info"
                        :href="
                          '/HRvecfiles/' +
                            conditions_transfers.documentsLinkfile
                        "
                        target="_blank"
                        >เสร็จสิ้น</v-btn
                      >
                    </v-col>
                    <v-col class="shrink" v-else>
                      <v-btn color="primary" to="/college/conditions_branch"
                        >ดำเนินการ</v-btn
                      >
                      <v-btn text color="red" @click="VideoDialog()">
                        <v-icon large color="red">mdi-video</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                    {{
                      period_collegeFiles.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_collegeFiles.period_college_stop + ' 16:30:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- แจ้งเตือนรวบรวมแบบคำร้องขอย้าย -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              period_teacher_requests.period_college_enable === '1' &&
                period_teacher_requests.period_college_type ===
                  'teacher_request'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>
                        ดำเนินการ
                        <u>แนบไฟล์แบบคำร้องขอย้ายและเอกสารประกอบการพิจารณา </u>
                      </h2>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          period_teacher_requests.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        color="primary"
                        to="/college/transference_personnel"
                        >ดำเนินการ</v-btn
                      >
                      <v-btn text color="red" @click="VideoDialogCondition()">
                        <v-icon large color="red">mdi-video</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                    {{
                      period_teacher_requests.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_teacher_requests.period_college_stop + ' 16:30:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- พิจารณารับยายสายบริหาร -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              perioddiscuss.period_college_enable === '1' &&
                perioddiscuss.period_college_type === 'discuss_college'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h3>
                        ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย
                        <u>ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายบริหาร</u>
                      </h3>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          perioddiscuss.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        color="primary"
                        to="/college/transference_manage_location"
                        >ดำเนินการ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ รายงานผลการพิจารณา <u>รับย้ายสายบริหาร</u> :
                    {{
                      perioddiscuss.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="perioddiscuss.period_college_stop + ' 16:30:00'"
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- พิจารณารับย้ายสายการสอนครู -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              period_teacher_moves.period_college_enable === '1' &&
                period_teacher_moves.period_college_type === 'discuss_teacher'
            "
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h3>
                        ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย
                        <u>ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายการสอน (ครู)</u>
                      </h3>
                      <h3>
                        ก่อนระบบปิดในวันที่
                        {{
                          period_teacher_moves.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        color="primary"
                        to="/college/transference_personnel_movein"
                        >ดำเนินการ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ รายงานผลการพิจารณา <u>รับย้ายสายการสอน</u> :
                    {{
                      perioddiscuss.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_teacher_moves.period_college_stop + ' 16:30:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- วิทยาลัยรายงานอัตรากำลัง  -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              period_colleges.period_college_enable === '1' &&
                period_colleges.period_college_type === 'update_college'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>รายงาน<u>อัตรากำลัง</u></h2>
                      <h3>
                        ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                        {{
                          period_colleges.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn color="primary" to="/college/rate_workforce_g"
                        >ดำเนินการ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ งานอัตรากำลังวันที่ :
                    {{
                      period_colleges.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_colleges.period_college_stop + ' 16:30:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>

        <!-- วิทยาลัยรายงานตัว ครูย้าย  -->
        <v-hover v-slot="{ hover }">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="pa-2"
            v-if="
              period_colleges_reportmv.period_college_enable === '1' &&
                period_colleges_reportmv.period_college_type ===
                  'reportmove_teacher'
            "
          >
            <v-row>
              <v-col cols="6">
                <v-alert
                  outlined
                  prominent
                  icon="mdi-bell-ring"
                  type="error"
                  border="left"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      <h2 class="blue--text">
                        <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                      </h2>
                      <h2>รับรายงานตัว<u>ครูย้ายกรณีปกติ</u></h2>
                      <h3>
                        ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                        {{
                          period_colleges_reportmv.period_college_stop
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        color="primary"
                        to="/college/conditons_transfer_success"
                        >ดำเนินการ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
              <v-col cols="6">
                <div class="text-center">
                  <h3>
                    ปิดระบบ งานอัตรากำลังวันที่ :
                    {{
                      period_colleges_reportmv.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    <br />
                    เหลือเวลาอีก
                  </h3>
                  <flip-countdown
                    :deadline="
                      period_colleges_reportmv.period_college_stop + ' 16:30:00'
                    "
                    :labels="labels"
                  ></flip-countdown>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          :value="Number(showAlldata.count_all).toLocaleString()"
          color="primary"
          icon="mdi-account-group"
          title="ครูบุคลากรทั้งหมด"
          sub-icon="mdi-clock"
          sub-text="ALL Personnel"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          :value="Number(showAlldata.count_tech).toLocaleString()"
          color="primary"
          icon="mdi-account-group"
          title="ข้าราชการครู"
          sub-icon="mdi-clock"
          sub-text="Teacher"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          :value="Number(showAlldata.count_dr).toLocaleString()"
          color="primary"
          icon="mdi-account-group"
          title="ผู้อำนวยการวิทยาลัย"
          sub-icon="mdi-clock"
          sub-text="Director"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          :value="Number(showAlldata.count_se_dr).toLocaleString()"
          color="primary"
          icon="mdi-account-group"
          title="รองผู้อำนวยการ"
          sub-icon="mdi-clock"
          sub-text="Deputy Director"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          :value="Number(showAlldata.count_supervision).toLocaleString()"
          color="primary"
          icon="mdi-account-group"
          title="ศึกษานิเทศก์"
          sub-icon="mdi-clock"
          sub-text="Study supervision"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <base-material-stats-card
          :value="Number(showAlldata.count_perpare).toLocaleString()"
          color="primary"
          icon="mdi-account-group"
          title="ครูผู้ช่วย"
          sub-icon="mdi-clock"
          sub-text="Intense Preparation"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <base-material-chart-card
          :data="Personnel_chart.data"
          :options="Personnel_chart.options"
          :responsive-options="Personnel_chart.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            ข้าราชการครูและบุคลากรทางการศึกษา
          </h4>

          <template v-slot:actions>
            <v-icon class="mr-1" small>mdi-clock-outline</v-icon>
            <span class="caption grey--text font-weight-light"
              >updated 10 minutes ago</span
            >
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" lg="6">
        <base-material-chart-card
          :data="personnel_rangChart.data"
          :options="personnel_rangChart.options"
          color="success"
          hover-reveal
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" color="info" icon v-on="on">
                  <v-icon color="info">mdi-refresh</v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn v-bind="attrs" light icon v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            ข้อมูลวิทยฐานะข้าราชการครู
          </h4>

          <template v-slot:actions>
            <v-icon class="mr-1" small>mdi-clock-outline</v-icon>
            <span class="caption grey--text font-weight-light"
              >updated 4 minutes ago</span
            >
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col cols="12" md="12">
        <base-material-card color="success" class="px-5 py-3">
          <template v-slot:heading>
            <div class="font-weight-light">สาขาวิชาเอก</div>

            <div class="font-weight-light">ข้อมูลสาขาวิชาในระบบ</div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="header_branchs"
              :items="showbranchdata"
              :search="search_branch"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search_branch"
                  label="ค้นหา :"
                  class="mx-4"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog v-model="VideoDialogYoutube" persistent max-width="80%">
        <v-card>
          <div class="text-center">
            <v-btn text large rounded @click="VideoDialogYoutubeStop()"
              ><v-icon dark>mdi-close</v-icon></v-btn
            >
            <iframe
              width="100%"
              height="600px"
              src="https://www.youtube.com/embed/iVZy90tWSZg"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- Video Dialog -->
    <v-layout>
      <v-dialog
        v-model="VideoDialogYoutubeCondition"
        persistent
        max-width="80%"
      >
        <v-card>
          <div class="text-center">
            <v-btn text large rounded @click="VideoDialogYoutubeStop()"
              ><v-icon dark>mdi-close</v-icon></v-btn
            >
            <iframe
              width="100%"
              height="600px"
              src="https://www.youtube.com/embed/XFm0GzKK_kQ"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "DashboardDashboard",
  components: { FlipCountdown },
  data() {
    return {
      headers: [
        { text: "ชื่อสถานศึกษา", align: "left", value: "college_name" },
        { text: "อำเภอ", align: "left", value: "prefecture_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ประเภทสถานศึกษา", align: "left", value: "collegetype_name" },
        { text: "เบอร์โทร", align: "left", value: "collegeinfo_phone" }
      ],
      header_branchs: [
        { text: "รหัสสาขา", align: "center", value: "id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "รายละเอียด", align: "left", value: "detail_branch" }
      ],
      search: "",
      search_branch: "",
      pagination: {},
      ApiKey: "HRvec2021",

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      personnel_rangChart: {
        data: {
          labels: ["ครูผู้ช่วย", "คศ.1", "คศ.2", "คศ.3", "คศ.4", "คศ.5"],
          series: [[0, 0, 0, 0, 0, 0]]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },

      Personnel_chart: {
        data: {
          labels: [
            "ผู้อำนวยการ",
            "รองผู้อำนวยการ",
            "ครู",
            "ครูผู้ช่วย",
            "ศึกษานิเทศก์"
          ],
          series: [[0, 0, 0, 0, 0]]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 200,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 10
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 100%)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },

      user: [],
      showAlldata_rang: [],
      colleges: [],
      provinces: [],
      prefectures: [],
      regions: [],
      region_ena: true,
      showAlldata: {},
      showcollegedata: {},
      showbranchdata: [],
      period_colleges: [],
      period_college_moves: [],
      period_teacher_moves: [],
      rate_work_g_all: [],
      conditions_transfers: [],
      loading: true,
      dialogLoading: true,
      interval: {},
      value: 0,
      perioddiscuss: [],
      periodTeachers: [],
      periodManages: [],
      period_teacher_requests: [],
      period_vecprovinceTeacher: [],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      period_vecprovincemanage: [],
      VideoDialogYoutube: false,
      VideoDialogYoutubeCondition: false,
      period_collegeFiles: [],
      periodassteachs: [],
      period_colleges_reportmv: [],
      manage_assistant_teachers: [],
      countmanage_assistant_teachers: [],
      period_college_redirector: []
    };
  },

  async mounted() {
    await this.getuser();
    await this.manage_assistant_teacherQueryAll();

    await this.periodassteachQuery(); /* รายงานตัวครูผู้ช่วย */
    await this.periodQueryTeacher(); /* ย้ายครู */
    await this.periodQueryManage(); /* ย้ายผู้บริหาร */
    await this.teacher_requestQuery(); /* รวบรวมแบบคำร้องขอย้ายครู */
    await this.period_college_moveQuery(); /* เงือนไขการย้าย */
    await this.CollegeFileQuery(); /* เงือนไขการย้าย */
    await this.period_teacher_moveQuery(); /* ขอความเห็นจากคณะกรรมการสถานศึกษาย้ายสายการสอน */
    await this.periodDiscussQuery(); /* ขอความเห็นจากคณะกรรมการสถานศึกษาย้ายสายบริหาร */
    await this.period_collegeQuery(); /* อัตรากำลัง */
    await this.period_collegemvQuery(); /* อัตรากำลัง */

    await this.getAlldata();
    await this.getAlldata_rang();
    await this.getPersonnelChart();
    await this.getPersonnel_rangChart();
    await this.getAllbranchdata();
    await this.conditions_transferQuery();
    await this.period_collegeSedirectors();

    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);

    this.loading = false;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async period_collegeSedirectors() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "20101"
      });
      this.period_college_redirector = result_period_college.data;
    },

    async VideoDialogYoutubeStop() {
      var videos = document.querySelectorAll("iframe, video");
      Array.prototype.forEach.call(videos, function(video) {
        if (video.tagName.toLowerCase() === "video") {
          video.pause();
        } else {
          var src = video.src;
          video.src = src;
        }
      });
      this.VideoDialogYoutube = false;
      this.VideoDialogYoutubeCondition = false;
    },
    async VideoDialogCondition() {
      this.VideoDialogYoutubeCondition = true;
    },
    async VideoDialog() {
      this.VideoDialogYoutube = true;
    },

    async getuser() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        ApiKey: "HRvec2021"
      });
      this.user = result.data;
    },
    async manage_assistant_teacherQueryAll() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_college_code: this.user.college_code,
        mt_admissions_status: "receipt"
      });
      this.manage_assistant_teachers = result.data;
      let data = this.manage_assistant_teachers;
      let sum = 0;
      let countcf = 0;
      data.forEach(value => {
        sum += 1;
        if (value.mt_committe_status == "CF") {
          countcf += 1;
        }
      });
      let nullsCounts = sum - countcf;
      this.countmanage_assistant_teachers.sum = sum;
      this.countmanage_assistant_teachers.countcf = countcf;
      this.countmanage_assistant_teachers.nullsCounts = nullsCounts;
    },

    async periodassteachQuery() {
      let result;
      result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnable: "1",
        periodAssTeachID: "102"
      });
      this.periodassteachs = result.data;
    },

    async periodQueryTeacher() {
      let result;
      result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "teacher"
      });
      this.periodTeachers = result.data;
    },

    async periodQueryManage() {
      let result;
      result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "manage"
      });
      this.periodManages = result.data;
    },

    async teacher_requestQuery() {
      let result;
      result = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "teacher_request"
      });
      this.period_teacher_requests = result.data;
    },

    async CollegeFileQuery() {
      let result;
      result = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable_file: "1",
        period_college_type: "movement_college"
      });
      this.period_collegeFiles = result.data;
    },

    async period_college_moveQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "movement_college"
      });
      this.period_college_moves = result_period_college.data;
    },

    async period_teacher_moveQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "discuss_teacher"
      });
      this.period_teacher_moves = result_period_college.data;
    },

    async periodDiscussQuery() {
      let result_period;
      result_period = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "discuss_college"
      });
      this.perioddiscuss = result_period.data;
    },

    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "update_college"
      });
      this.period_colleges = result_period_college.data;
    },

    async period_collegemvQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "reportmove_teacher"
      });
      this.period_colleges_reportmv = result_period_college.data;
    },

    async conditions_transferQuery() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http.post("conditions_transfer.php", {
        ApiKey: this.ApiKey,
        college_code: userSession.user_name,
        time_s: this.period_college_moves.period_college_times,
        year_s: parseInt(this.period_college_moves.period_college_year) + 543
      });
      this.conditions_transfers = result.data;
    },

    async getAlldata() {
      let result = await this.$http.post("show_dashboard_all.php", {
        college_code: this.user.college_code
      });
      this.showAlldata = result.data;
    },

    async getAlldata_rang() {
      let result = await this.$http.post("show_dachboard_rang.php", {
        college_code: this.user.college_code
      });
      this.showAlldata_rang = result.data;
    },

    async getPersonnelChart() {
      this.Personnel_chart.data.series = [
        [
          this.num_dr,
          this.num_se_dr,
          this.num_se_tech,
          this.num_se_techprepare,
          this.num_se_supervision
        ]
      ];
    },

    async getPersonnel_rangChart() {
      this.personnel_rangChart.data.series = [
        [
          this.num_rang_0,
          this.num_rang_1,
          this.num_rang_2,
          this.num_rang_3,
          this.num_rang_4,
          this.num_rang_5
        ]
      ];
    },

    async getAllbranchdata() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.showbranchdata = result.data;
    }
  },
  computed: {
    checkstatus_confirm() {
      let status_confirm = this.conditions_transfers.status_confirm;
      let result;
      if (status_confirm == "receive") {
        result = "ส่วนกลางได้รับเอกสาร ถูกต้องสมบูรณ์";
      } else if (status_confirm == "receive_but") {
        result = "ส่วนกลางได้รับเอกสาร แต่ไม่สมบูรณ์";
      } else if (status_confirm == "dt_receive") {
        result = "ส่วนกลางยังไม่ได้รับเอกสาร กรุณาแนบไฟล์เงือนไข";
      } else if (status_confirm == "relocated") {
        result = "ไม่ประสงค์รับย้าย";
      } else {
        result = "ส่วนกลางยังไม่ได้รับเอกสาร กรุณาแนบไฟล์เงือนไข";
      }
      return result;
    },
    year_now() {
      let today = new Date();
      let yyyy = today.getFullYear() + 543;
      today = yyyy;
      return today;
    },
    num_dr() {
      let result = parseInt(this.showAlldata.count_dr);
      return result;
    },
    num_se_dr() {
      let result = parseInt(this.showAlldata.count_se_dr);
      return result;
    },
    num_se_tech() {
      let result = parseInt(this.showAlldata.count_tech);
      return result;
    },
    num_se_techprepare() {
      let result = parseInt(this.showAlldata.count_perpare);
      return result;
    },
    num_se_supervision() {
      let result = parseInt(this.showAlldata.count_supervision);
      return result;
    },

    num_rang_0() {
      let result = parseInt(this.showAlldata_rang.count_rang0);
      return result;
    },
    num_rang_1() {
      let result = parseInt(this.showAlldata_rang.count_rang1);
      return result;
    },
    num_rang_2() {
      let result = parseInt(this.showAlldata_rang.count_rang2);
      return result;
    },
    num_rang_3() {
      let result = parseInt(this.showAlldata_rang.count_rang3);
      return result;
    },
    num_rang_4() {
      let result = parseInt(this.showAlldata_rang.count_rang4);
      return result;
    },
    num_rang_5() {
      let result = parseInt(this.showAlldata_rang.count_rang5);
      return result;
    }
  }
};
</script>

<style scoped>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.more {
  margin: 30px 0;
}
button {
  margin: 10px;
}
.flipcountdown {
  width: 40%;
  height: 40%;
}
</style>
